import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getAmount } from "../../reducers/commonReducer";
import AmountAction from "./AmountAction";
import SideBaar from "../../Components/SideBaar";

const AmountList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getAmount())
  }, [navigate, dispatch]);
  const { getAmountList } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Amount List</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>User Name</th>  
                <th>Amount</th>
                <th>Cashback Amount</th>
                <th>Cashback</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!!getAmountList &&
                getAmountList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.id}</td>
                      <td>{data?.name}</td>
                      <td>{((data?.cashback / 100) * data?.name ).toFixed()}</td>
                      <td>{data?.cashback.toFixed(2)}%</td>
                      <td><AmountAction status={data?.status} id={data?.id} amount={data?.name} cashback={data?.cashback}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default AmountList;
