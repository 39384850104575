import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getTDS, restRecord } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";
import { Button } from "react-bootstrap";

const TDSList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getTDS({page:currentPage}))
  }, [navigate, dispatch]);
  const {getTDSList, getTDSBlank} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">TDS</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>TDS Amount</th>
                <th>PaymentId</th>
                <th>OnAmount</th>
                <th>Percentage</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {!!getTDSList &&
                getTDSList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.tdsAmount}</td>
                      <td>{data?.payment.substr(data?.payment?.length - 6)}</td>
                      <td>{data?.onAmount}</td>
                      <td>{data?.percentage}%</td>
                      <td>{moment(data.createdAt).format('DD/MM/YYYY hh:mm:ss')}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!getTDSBlank && getTDSBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getTDS({page:currentPage + 1}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TDSList;
