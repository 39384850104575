import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { tambolaFutureGames, upcommingGames } from '../../../reducers/commonReducer';

const schema = yup.object().shape({
  name: yup.string().required(),
  title: yup.string(),
  // start: yup
  //   .date()
  //   .min(new Date(), 'Date and time must be after the current date and time')
  //   .required('Date and time is required'),
  start: yup
  .date()
  .when('category', {
    is: (category) => category !== 'ticketBased',
    then: yup
      .date()
      .min(new Date(), 'Date and time must be after the current date and time')
      .required('Date and time is required')
  }),
  ticketSet: yup.string().required(),
  category: yup.string().required(),
  box: yup
    .number()
    .required('Box is required')
    .moreThan(0, 'Box must be greater than 0')
    .test('isDivisibleByTwo', 'Please Enter valid number', function (value) {
      const { ticketSet } = this.parent;
      if (ticketSet) {
        return value % ticketSet === 0;
      }
      return true;
    })
    .test('isGreaterThanOrEqualToExisting', 'Please Enter Greater than or equal then existing box.', function (value) {
      const { graterThenExist } = this.parent;
      let boxValidate = graterThenExist >= 1 ? graterThenExist : 1
      return value >= boxValidate;
    }),
  price: yup.number("Must be number")
  .required("price is required")
  .positive()
  .integer()
  .min(5, "Minimum is 5 Rs"),
  loop: yup.string(),
  jackpotPrice: yup.string(),
});
const schemaUpdate = yup.object().shape({
  name: yup.string().required(),
  title: yup.string(),
  ticketSet: yup.string().required(),
  autoMatic: yup.string().required(),
  box: yup
    .number()
    .required('Box is required')
    .moreThan(0, 'Box must be greater than 0')
    .test('isDivisibleByTwo', 'Please Enter valid number', function (value) {
      const { ticketSet } = this.parent;
      if (ticketSet) {
        return value % ticketSet === 0;
      }
      return true;
    })
    .test('isGreaterThanOrEqualToExisting', 'Please Enter Greater than or equal then existing box.', function (value) {
      const { graterThenExist } = this.parent;
      let boxValidate = graterThenExist >= 1 ? graterThenExist : 1
      return value >= boxValidate;
    }),
  price: yup.number("Must be number")
  .required("price is required")
  .positive()
  .integer()
  .min(5, "Minimum is 5 Rs"),
  loop: yup.string(),
  jackpotPrice: yup.string(),
}); 

function ActionTambolaGame(props) {
  const dispatch = useDispatch();
  const {
    ticketsNum,
    title,
    eboxes,
    ename,
    eloop,
    btntitle,
    ejackpotPrice,
    erewards,
    estart,
    eticketPrice,
    eticketSet,
    ecategory,
    evideoid,
    eid,
    apiFunction,
    eshowMessage,
    eshowOnTop,
    eunsoldwinner,
    eautoMatic,
    btnFloat
  } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
  };
  const { getTambolaRewardList, addTambolaByAdminWaiting, getCategoriesList } = useSelector((state) => state.commonReducer);
  let rewdObject = {};
  if(!!erewards ) {
    erewards.map((data) => {
      rewdObject[data?._id?.id] = data?.prize;  
    });

  }

  return (
    <>
        <Button variant="success" className={`m-1 py-0 px-1 ${btnFloat}`} onClick={handleShow}>
        {btntitle ? btntitle : <i className="bi bi-pen-fill"></i>}
        </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Game</Modal.Title>
        </Modal.Header>
        <Formik
        enableReinitialize={true}
          validationSchema={values => {
            if (title === 'Edit') {
              return schemaUpdate;
            } else {
              return schema;
            }
          }}
          initialValues={{
            name: ename,
            start: estart,
            box: eboxes,
            graterThenExist:eboxes,
            ticketSet: eticketSet,
            category: ecategory,
            price: eticketPrice,
            loop: eloop,
            jackpotPrice: ejackpotPrice,
            unsoldwinner: eunsoldwinner,
            rewards: !!getTambolaRewardList && getTambolaRewardList.map((data) => {
              if (((localStorage.getItem("appSlug")?.length > 0) && 
                  (data?.name === "Full Sheet" || data?.name === "Half Sheet")) || 
                  ((localStorage.getItem("website")?.length > 0) && 
                  (data?.name === "Set Corner"))
                  ) {
                return {
                  _id: data?.id,
                  prize: 0,
                  name: data?.name,
                }; 
              }
              return {
                _id: data?.id,
                prize:  rewdObject[data?.id] !== undefined ? rewdObject[data?.id] : 0,
                name: data?.name,
              };
            }),
            // !!getTambolaRewardList &&
            //     getTambolaRewardList.map((data) => (
            //       {
            //       _id: data?.id,
            //       prize: rewdObject[data?.id] !== undefined ? rewdObject[data?.id] : 0,
            //       name: data?.name,
            //     }))
          
            videoid: evideoid,
            id: eid,
            title:title,
            showMessage:eshowMessage,
            showOnTop:eshowOnTop ? eshowOnTop : false,
            autoMatic:eautoMatic
          }}
          onSubmit={async (values, { resetForm }) => {
            if (title === 'Add' && !values?.rewards[7].prize) {
              alert('Full house is required');
              return false;
            }
            dispatch(
              apiFunction(values)
            );
            if(localStorage.getItem("userRole") === "1"){
              dispatch(tambolaFutureGames());
            } else{
              (localStorage.getItem("userRole") !== "5") && dispatch(upcommingGames())
            }
            handleClose();
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="px-3 pb-3">
              <Row className="pb-2">
                <h3 className="mb-0">{!!ticketsNum && ticketsNum.map((data) => data + `, `)}</h3>
              </Row>
              {/* start for supervisor Add video ID */}
                {
                  (localStorage.getItem('userRole') === "5")  && <Row>
                    <Col md={12}>
                      <Form.Group className="pb-2">
                        <Form.Label className="mb-0">Video ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Videoid"
                          name="videoid"
                          value={values.videoid}
                          onChange={handleChange}
                          isInvalid={!!errors.videoid}
                        />
                        <Form.Control.Feedback type="invalid">{errors.videoid}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                }
              {/* end for supervisor Add video ID */}
              {((localStorage.getItem('userRole') === "1") || (localStorage.getItem('userRole') === "2")) && <>
                <Row className="pb-2">
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">GameName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Game Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="mb-1">Select Sets</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('ticketSet', +e.target.value);
                      }}
                      name="ticketSet"
                      className="form-control"
                      value={values.ticketSet}
                      isInvalid={!!errors.ticketSet}
                      disabled={title === 'Edit' ? true : false}
                    >
                      <option value="">Select Set</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="6">6</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.ticketSet}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Box</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter box"
                      name="box"
                      min={eboxes}
                      value={values.box}
                      onChange={handleChange}
                      isInvalid={!!errors.box}
                      disabled={values.ticketSet ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">{errors.box}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label className="mb-1">Select Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="category"
                      className="form-control"
                      value={values.category}
                      isInvalid={!!errors.category}
                    >
                      <option>Select Category</option>
                      {!!getCategoriesList && getCategoriesList?.map((data, index) => <option value={data} key={index}>{data === "timeBased" && "Time Based" || data === "ticketBased" && "Ticket Based" || data === "eventBased" && "Event Based"}</option>)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Start Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="start"
                      value={values?.start ? moment(values.start).format('YYYY-MM-DDTHH:mm') : ''}
                      onChange={handleChange}
                      isInvalid={!!errors.start}
                    />
                    <Form.Control.Feedback type="invalid">{errors.start}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={2}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Ticket Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Jackpot Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Price"
                      name="jackpotPrice"
                      value={values.jackpotPrice}
                      onChange={handleChange}
                      isInvalid={!!errors.jackpotPrice}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.jackpotPrice}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Video ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Videoid"
                      name="videoid"
                      value={values.videoid}
                      onChange={handleChange}
                      isInvalid={!!errors.videoid}
                    />
                    <Form.Control.Feedback type="invalid">{errors.videoid}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {
                 ( title !== "Edit") &&  <Col md={2}>
                 <Form.Group className="pb-2">
                   <Form.Label className="mb-1">UnsoldWin</Form.Label>
                   <Form.Control
                     as="select"
                     onChange={async (e) => {
                       await setFieldValue('unsoldwinner', e.target.value);
                     }}
                     name="unsoldwinner"
                     className="form-control"
                     value={values.unsoldwinner}
                     isInvalid={!!errors.unsoldwinner}
                   >
                    <option value={""}>Select</option>
                     <option value={false}>No</option>
                     <option value={true}>Yes</option>
                   </Form.Control>
                 </Form.Group>
               </Col>
                }
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="mb-0">Select Game Loop</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('loop', e.target.value);
                      }}
                      name="loop"
                      className="form-control"
                      value={values.loop}
                      isInvalid={!!errors.loop}
                    >
                      <option value="">Select Loop</option>
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.loop}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Show Message</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Show Message"
                      name="showMessage"
                      value={values.showMessage}
                      onChange={handleChange}
                      isInvalid={!!errors.showMessage}
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">{errors.showMessage}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label className="mb-1">Show On Top</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="showOnTop"
                      className="form-control"
                      value={values.showOnTop}
                      isInvalid={!!errors.showOnTop}
                    >
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.showOnTop}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-1">AutoMatic</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('autoMatic', e.target.value);
                      }}
                      name="autoMatic"
                      className="form-control"
                      value={values.autoMatic}
                      isInvalid={!!errors.autoMatic}
                    >
                      <option value={''}>Select Status</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label className="mb-0">Rewards</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Label className="mb-0">Rewards Price</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <FieldArray name="rewards">
                    {() => (
                      <>
                        {!!values.rewards &&
                          values.rewards.map((data, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Row>
                                  <Col md={6} sm={12}>
                                    <Field
                                      className="p-2 mb-1 w-100"
                                      value={values.rewards[index].name}
                                      type="text"
                                      name={`rewards.${index}.name`}
                                      disabled
                                    />
                                  </Col>
                                  <Col md={6} sm={12}>
                                    <Form.Control
                                      type="number"
                                      onChange={handleChange}
                                      value={values.rewards[index].prize}
                                      name={`rewards.${index}.prize`}
                                      isInvalid={!!errors.rewards}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            );
                          })}
                      </>
                    )}
                  </FieldArray>
                  <Form.Control.Feedback type="invalid">{errors.rewards}</Form.Control.Feedback>
                </Col>
              </Row>
                </>
              }
              {
                localStorage.getItem('userRole') === "2" && <>
                <Row>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Video ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Videoid"
                      name="videoid"
                      value={values.videoid}
                      onChange={handleChange}
                      isInvalid={!!errors.videoid}
                    />
                    <Form.Control.Feedback type="invalid">{errors.videoid}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {
                   ( title !== "Edit") && <Col md={6}>
                   <Form.Group className="pb-2">
                     <Form.Label className="mb-1">UnsoldWin</Form.Label>
                     <Form.Control
                       as="select"
                       onChange={async (e) => {
                         await setFieldValue('unsoldwinner', e.target.value);
                       }}
                       name="unsoldwinner"
                       className="form-control"
                       value={values.unsoldwinner}
                       isInvalid={!!errors.unsoldwinner}
                     >
                       <option value={false}>No</option>
                       <option value={true}>Yes</option>
                     </Form.Control>
                   </Form.Group>
                 </Col>
                }
                </Row>
                </>
                }
              <Modal.Footer>
                <div id='tmpRemoveSubmitbutton'>
                  <Button type="submit" variant='success' className="mt-1" disabled={addTambolaByAdminWaiting === 0 ? true : false}>
                    Submit
                  </Button>
                </div>
                <div className="loading-area" id="loading-icon">
                  <div className="loader">
                      <div></div>
                  </div>
                </div> 
                <Button variant="danger" onClick={handleClose} className="mt-1">
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ActionTambolaGame;
