import React, { useEffect } from "react";
import "../../app.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import tambolasub from "../../images/tambolasub.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionPlan } from "../../reducers/commonReducer";

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let width = window.innerWidth;
  let dateYear = new Date();
  useEffect(() => {
    dispatch(getSubscriptionPlan())
  },[dispatch])
  const {getSubscriptionPlanList} = useSelector((state) => state.commonReducer);
  return (
    <> 
        <div className="row sub-bg pt-3 pb-3">
          {
            !!getSubscriptionPlanList && getSubscriptionPlanList?.map((data, index) => {
              return(
                <Col key={index} md={12} className={`p-0 ${width > 500 ? "w-40  loginPanel" : "w-100"}`}>
                <div className="form-logo text-center">
                  <img className={`${width > 500 ? "w-40" : "w-100 px-2"}`} src={tambolasub}  alt="Subscription"/>
                </div>
                <div className="text-center mt-1 price-strip">
                  <h4>
                     <span className="">{data?.price} Rs./monthly</span>
                  </h4>
                </div>
                <div className={`py-2 mt-0 ${width > 500 ? `px-5` : `px-3`}`}>
                    <div className="mt-2 features-point">
                        <p><span><i className="bi bi-check-lg"></i></span> Variety of design</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Realistic voice</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Entertaining sound effects</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Seperate player link,agent link and admin link</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Full 600th tickets</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Players can chat each other</p>
                        <p><span><i className="bi bi-check-lg"></i></span> Multiple dividends including fullsheet bonus, haftsheet bonus</p>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <Button
                          type="submit"
                          className="button-color login-btn px-4 w-100"
                          onClick={() => navigate(`/subscription?planid=${data?.id}&price=${data?.price}`)}
                        >
                         Buy Now
                        </Button>
                      </div>
                      {/* <Row className="form-logo text-center">
                        <Col md={12}>
                        <img className={`mt-2 ${width > 500 ? "w-100" : "w-100 px-0"}`} src={ticket}  alt="ticket"/>
                        </Col>
                      </Row> */}
                      <div className="col-12 text-center mt-4">
                        <p>
                          All Rights Reserved by 💗 Tambola Game ©{" "}
                          {dateYear.getFullYear()}. Please connect on WhatsApp at <Button className='m-1 px-1 py-0' variant='success' onClick={() => window.location.replace(`https://api.whatsapp.com/send?phone=+919588377039&text=Hi&source=&data=&app_absent=`)}><i className="bi bi-whatsapp"></i> 9588377039</Button> if you have any queries.
                        </p>
                      </div>
                </div>
              </Col>
              )
            })
          }
        </div>
    </>
  );
};

export default Subscription;
