import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { userPayments } from '../../reducers/commonReducer';
import logo from '../../images/logo.png';

export const UserPayment = () => {
    const dispatch = useDispatch();
  const { userid, amount } = useParams();

  useEffect(()  => {
    const OnLoadData = async () => {
    await dispatch(userPayments({userid, amount : +amount}))
    await document?.forms["myForm"].submit()
    }
    OnLoadData(); 
  }, [dispatch, amount, userid]);

  const {userPaymentsDetails} = useSelector((state) => state.commonReducer);
  return (
    <div className="response-bg">
      <img className='mb-4' src={logo} alt="Logo" />
      <h1 className="payment-loding">Please Wait.......</h1>
      {userPaymentsDetails?.paymentUrl && userPaymentsDetails?.phonePay ? 
         window.location.replace(userPaymentsDetails?.paymentUrl) 
        : 
        <form name="myForm" id="myForm"  
          action={userPaymentsDetails?.paymentUrl} 
          method="POST"
        >
        <input type="hidden" name="firstName" value={userPaymentsDetails?.firstName ? userPaymentsDetails?.firstName : ''} />
        <input type="hidden" name="transaction_id" value={userPaymentsDetails?.transaction_id ? userPaymentsDetails?.transaction_id : ''} />
        <input type="hidden" name="amount" value={userPaymentsDetails?.amount ? userPaymentsDetails?.amount : ''} />
        <input type="hidden" name="email" value={userPaymentsDetails?.email ? userPaymentsDetails?.email : ''} />
        <input type="hidden" name="phone" value={userPaymentsDetails?.phone ? userPaymentsDetails?.phone : ''} />
        <input type="hidden" value="Submit" />
      </form>    
      }
    </div>
  );
};

