import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {  restRecord, subscriptionPayments } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";
import { Button, Form } from "react-bootstrap";

const SubscriptionPaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(subscriptionPayments({currentPage:currentPage}))
  }, [dispatch, navigate]);
  const {subscriptionPaymentList, subscriptionPaymentBlank} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Subscription Payments</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <Row className="mb-2 user-table">
          <Col md={11}>
            <label>
              <b>Enter Phone Number</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Col>
          <Col md={1}>
            <Button className="mt-4 w-50 p-1" variant="success" type="submit" onClick={() => {
              dispatch(restRecord())
              dispatch(subscriptionPayments({phone}))
            }}>
            <i className="bi bi-search"></i>
            </Button>
            <Button className="mt-4 w-50 p-1" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
            <i className="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Domain</th>
                <th>Date</th>
                <th>Expire Date</th>
                <th>Payment Status</th>
                <th>Theme</th>
              </tr>
            </thead>
            <tbody>
              {!!subscriptionPaymentList &&
                subscriptionPaymentList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.phone}</td>
                      <td>{data?.email}</td>
                      <td>{data?.amount}/-</td>
                      <td>{data?.domain}</td>
                      <td>{moment(data.createdAt).format('DD/MM/YYYY hh:mm:ss')}</td>
                      <td>{moment(data.expire).format('DD/MM/YYYY hh:mm:ss')}</td>
                      <td>{((data?.status === 0) && <span className="text-secondary"><b>Pending</b></span>) ||
                          ((data?.status === 1) && <span className="text-danger"><b>Reject</b></span>) ||
                          ((data?.status === 2) && <span className="text-success"><b>Success</b></span>)}</td>
                      <td>{data?.theme}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!subscriptionPaymentBlank && subscriptionPaymentBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(subscriptionPayments({currentPage:currentPage + 1}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default SubscriptionPaymentList;
