import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import {Button, Col, Row} from "react-bootstrap";
import { getUserDetails, getUserTransactions, updateUserDetails } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";

const Transactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const usertype = queryParameters.get("usertype");
  const userid = queryParameters.get("userid");
  const username = queryParameters.get("username");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getUserTransactions({userid}))
    dispatch(getUserDetails(userid))
  }, [dispatch, navigate, usertype, userid, username]);
  const { getUserTransactionList, getUserDetail} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0"><b className={usertype === "true" ? "text-success" : ""}>({username})</b> Transactions</h3>
          </Col>
          <Col md={6}>
            <Button variant={getUserDetail?.status ? 'danger' : 'success'} className='w-auto float-right' onClick={async() => {
              await dispatch(updateUserDetails({
                id:userid,
                username,
                status: getUserDetail?.status ? "false" : 'true'
              }
              ))
              dispatch(getUserDetails(userid))
              }}>{getUserDetail?.status ? `Block` : `UnBlock`}
            </Button>
          </Col>
        </Row>
        
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Comment</th>
                <th>View</th>
                <th>Date & Time</th>
                <th>In</th>
                <th>Out</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {!!getUserTransactionList &&
                getUserTransactionList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {data?.comment}
                      </td>
                      <td>
                        {
                          (data?.game_history_id && data?.game_id === "651f9c4ea5e67037d308e120") && <Link to={`/game-history-board?gameId=${data?.game_id}&userid=${data?.type === "ADD" ? data?.toUser?.id : data?.fromUser?.id}&gameHistoryid=${data?.game_history_id?.id}`}>View</Link>
                        }
                      </td>
                      <td>{moment(data?.createdAt).format('DD/MM/YYYY hh:mm:ss')}</td>
                      <td>{data?.type === 'ADD' && data?.trans_coins?.toFixed(2)}</td>
                      <td>{data?.type !== 'ADD' && data?.trans_coins?.toFixed(2)}</td>
                      <td>{data?.remaining_coins.toFixed(2)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default Transactions;
