import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getProfile, updatePassword,  } from "../../reducers/commonReducer";
import moment from "moment";
import ProfileAction from "./ProfileAction";
import SideBaar from "../../Components/SideBaar";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getProfile())
  }, [navigate, dispatch]);
  const {getProfileDetails} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Profile</h3>
          </Col>
          <Col md={6}>
            <ProfileAction api={updatePassword}/>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{getProfileDetails?.name}</td>
              </tr>
              <tr>
                <td>Prime</td>
                <td>{getProfileDetails?.prime ? <span className="text-success"><b>Yes</b></span> : <span className="text-danger"><b>No</b></span>}</td>
              </tr>
              <tr>
                <td>Coins</td>
                <td>{getProfileDetails?.coins?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Commission</td>
                <td>{getProfileDetails?.commission}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{moment(getProfileDetails?.createdAt).format('DD/MM/YYYY hh:mm:ss')}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default Profile;
