import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { multipleTambolaTicketBooking, multipleTambolaTicketBookingFromAgent, tambolaGameByID, tambolaGameHisTicketByGame } from '../../../reducers/commonReducer';

const schema = yup.object().shape({
  // username: yup.string().required(),
});

function TambolaBookTicket(props) {
  const dispatch = useDispatch();
  const { ticketsNum, bookticktgameId, collectTicket } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
  };
  const { getBoatList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Row className='mt-5'>
        <Col>
          <Button className="book-my-ticket" onClick={handleShow}>
            Book My Ticket
          </Button>
        </Col>
      </Row>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Book My Tickets</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            if(((localStorage.getItem("userRole") === "2") && (localStorage.getItem("website")?.length > 0)) || (localStorage.getItem("userRole") === "6")){
              await dispatch(multipleTambolaTicketBookingFromAgent(values))
            } else{
             await dispatch(multipleTambolaTicketBooking(values))
            }
            resetForm({ values: '' });
            await dispatch(tambolaGameHisTicketByGame({
            gameid:bookticktgameId
           }))
          await dispatch(tambolaGameByID({gameid:bookticktgameId}))
           window.location.reload(true);
          }}
          initialValues={{
            gameid: bookticktgameId,
            ticketSets: ticketsNum,
            username: '',
            name:'',
            sheet:''
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="px-3 pb-3">
              <Row className="pb-2">
                <h3 className="mb-0">{!!ticketsNum && ticketsNum.map((data) => data + `, `)}</h3>
              </Row>
              <Row className="pb-2">
                {
                  localStorage.getItem("appSlug")?.length > 0 ? 
                  <>
                   <Col md={6}>
                  <Form.Group>
                    <Form.Label>Boat Users</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('username', e.target.value);
                      }}
                      name="username"
                      className="form-control"
                      value={values.username}
                      isInvalid={!!errors.username}
                    >
                      <option value="">Select Boats</option>
                      {!!getBoatList &&
                        getBoatList?.map((data, index) => (
                          <option key={index} value={data?.username}>{data?.name}</option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>UserName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                  </Form.Group>
                </Col>
                  </> : 
                  <>
                  <Col md={6}>
                      <Form.Group className="pb-2">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                    </Form.Group>
                  </Col>
                  {
                    (!!collectTicket && collectTicket?.length === 3) && <Col md={6}>
                    <Form.Group>
                    <Form.Label>Half Sheet Bonus</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('sheet', e.target.value);
                      }}
                      name="sheet"
                      className="form-control"
                      value={values.sheet}
                      isInvalid={!!errors.sheet}
                    >
                      <option value="">Select Bonus</option>
                      <option value={"half"}>Yes</option>
                      <option value={''}>No</option>
                    </Form.Control>
                    </Form.Group>
                  </Col>
                  }
                   {
                    (!!collectTicket && collectTicket?.length === 6) && <Col md={6}>
                    <Form.Group>
                    <Form.Label>Full Sheet Bonus</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('sheet', e.target.value);
                      }}
                      name="sheet"
                      className="form-control"
                      value={values.sheet}
                      isInvalid={!!errors.sheet}
                    >
                      <option value="">Select Bonus</option>
                      <option value={'full'}>Yes</option>
                      <option value={''}>No</option>
                    </Form.Control>
                    </Form.Group>
                  </Col>
                  }
                  </>
                }
              </Row>
              <Button type="submit" className="mt-1">
                Submit
              </Button>
              <Button variant="danger" onClick={handleClose} className="mt-1">
                Close
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default TambolaBookTicket;
