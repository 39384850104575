import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getKYC, restRecord, updateKYC } from "../../reducers/commonReducer";
import moment from 'moment';
import KycAction from "./KycAction";
import { Button } from "react-bootstrap";
import { openInNewTab } from "../../const";
import SideBaar from "../../Components/SideBaar";

const KycList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getKYC({page:currentPage}))
  }, [navigate, dispatch]);
  const { getKYCList, getKYCBlank} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">KYC</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>UserName</th>
                <th>KYC Status</th>
                <th>Account</th>
                <th>AccountHolder</th>
                <th>IFSC</th>
                <th>ID Proof</th>
                <th>ID Proof Number</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getKYCList &&
                getKYCList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.user?.username}</td>
                      <td>{((data?.status === 0 && <span className='text-primary'>Pending</span>) || (data?.status === 1 && <span className='text-danger'>Fail</span>) || (data?.status === 2 && <span className='text-success'>Success</span>))}</td>
                      <td>{data?.account}</td>
                      <td>{data?.accountHolder}</td>
                      <td>{data?.ifsc}</td>
                      <td>
                      <>{data?.idproof} <br/><span className='d-flex'><Button onClick={() => openInNewTab(data?.frontSide)} variant="warning" className='m-1'>Front</Button><Button onClick={() => openInNewTab(data?.backSide)} variant="info" className='m-1'>Back</Button></span></>
                      </td>
                      <td>{data?.idproofNumber}</td>
                      <td>{moment(data.createdAt).format('DD/MM/YYYY hh:mm:ss')}</td>
                      <td>
                        <KycAction 
                          status={data?.status} 
                          id={data?.id}
                          api={updateKYC}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!getKYCBlank && getKYCBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getKYC({page:currentPage + 1}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default KycList;
