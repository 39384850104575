import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {getGames } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";
import { Button } from "react-bootstrap";

const GameTicketsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGames());
  }, [navigate, dispatch]);
  const {  } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Games Ticket Details</h3>
          </Col>
          <Col md={6}>
            <Button className="float-right" variant="info">Pending Amount 234444/-</Button>
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Ticket ID</th>
                <th>Ticket Price</th>
                <th>Total Ticket Sold</th>
                <th>Total Amount</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1.</td>
                    <td>Rekha</td>
                    <td>[345, 500]</td>
                    <td>50</td>
                    <td>2</td>
                    <td>100</td>
                    <td>+91 9876543211</td>
                </tr>
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default GameTicketsDetails;
