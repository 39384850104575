import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminSelf, userLogOut } from "../reducers/commonReducer";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import AP from "../images/logo.png";
import { Col, Row } from "react-bootstrap";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    if (apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  return (
    // <Container> 
    <div className="row header-full sticky-top px-1 shadow"> 
      <div className="container-fluid">
        <Row className="pt-1 pb-1 align-baseline">
          <Col md={2} sm={8} xs={8}>
            <Navbar.Brand>
              <NavLink to="/dashboard">
                <img className="w-100" alt="logo" src={AP} />
              </NavLink>
            </Navbar.Brand>
          </Col>
          <Col md={10} sm={4} xs={4} className="text-end">
            {
              ((localStorage.getItem("userRole") === "2") && (localStorage.getItem("goto") === "admin")) && <Button className="mx-2" variant="success" onClick={async() => {
                const apiResult = await dispatch(adminSelf({
                   "admintoken" : (localStorage.getItem("adminToken"))
                 }))
                 if(apiResult?.payload?.status === 1){
                   navigate(`/sub-admin`)
                 }
               }}>
                <i className="bi bi-skip-backward-fill"></i> Back To SuperAdmin
               </Button>
            }
            <Button type="submit" className="button-color" onClick={logout}>
              Log Out{" "}
            </Button>
          </Col>
        </Row>
      </div> 
      </div>  
    // </Container>
  );
};

export default HeaderMenu;
