import React, { useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import Cards from "../../Components/Card";
import { getDashboard } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import { Col, Row } from "react-bootstrap";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboard());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, [navigate, dispatch]);
  const { getDashboardData } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <div className="container content mt-3">
        <div className="row">
          <div className="col-md-4">
            <NavLink to="/complaints">
              <Cards
                title="Total Complaints"
                icon="bi-mailbox"
                background="bg-c-green"
                subtitle={getDashboardData?.complaint}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/complaints">
              <Cards
                title="Weekly Complaint"
                icon="bi-file-post-fill"
                background="bg-c-blue"
                subtitle={getDashboardData?.weeklyComplaint}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/complaints">
              <Cards
                title="Pending Complaints"
                icon="bi-mailbox"
                background="bg-c-green"
                subtitle={getDashboardData?.complaintPending}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Customer"
                icon="bi-file-post-fill"
                background="bg-c-blue"
                subtitle={getDashboardData?.customer}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Manager"
                icon="bi-shield-plus"
                background="bg-c-green"
                subtitle={getDashboardData?.manager}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Technician"
                icon="bi-person-gear"
                background="bg-c-blue"
                subtitle={getDashboardData?.technician}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Users"
                icon="bi-cart-plus"
                background="bg-c-green"
                subtitle={getDashboardData?.users}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/products">
              <Cards
                title="Total Products"
                icon="bi-people"
                background="bg-c-blue"
                subtitle={getDashboardData?.products}
              />
            </NavLink>
          </div>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default Dashboard;
