import { toast } from "react-toastify";

export const messages = async (msg, apiStatus) => {
  if (apiStatus === 1) {
    toast.success(msg );
  } else {
    toast.error(msg );
  }
};

export const postApi = async (api, type, body) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
      "x-admin": localStorage.getItem("adminToken"),
    },
    body: JSON.stringify(body),
  });
  if (!res.ok) {
    if (res.status === 403) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
  return await res.json();
};

export const allApi = async (api, type) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
      "x-admin": localStorage.getItem("adminToken"),
    },
  });
  if (!res.ok) {
    if (res.status === 403) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
  return await res.json();
};

export const postApiFile = async (api, type, userDetails) => {
  const formData = new FormData();
  Object.entries(userDetails).map(([key, value]) => {
    formData.append(key, value);
  });
  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
  myHeaders.append("x-admin", localStorage.getItem("adminToken"));
  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });
  if (!res.ok) {
    if (res.status === 403) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
  return await res.json();
};

export const postApiMultipleFile = async (api, type, userDetails) => {
  const formData = new FormData();
  Object.entries(userDetails).map(([key, value]) => {
    formData.append(key, value);
  });
  if (userDetails.images && userDetails.images.length > 0) {
    for (let i = 0; i < userDetails.images.length; i++) {
      formData.append(`images[${[i]}]`, userDetails.images[i]);
    }
  }
  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
  myHeaders.append("x-admin", localStorage.getItem("adminToken"));
  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });
  if (!res.ok) {
    if (res.status === 403) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
  return await res.json();
};
