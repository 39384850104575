import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import TambolaBookTicket from './TambolaBookTicket';
import SideBaar from "../../../Components/SideBaar";
import Header from "../../../Components/Header";
import moment from 'moment';
import { getBoats, tambolaGameByID, tambolaGameHisTicketByGame } from '../../../reducers/commonReducer';

const TambolaTicketBookingBoard = () => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const gameid = queryParameters.get("gameHistory");
  const ticketSet = queryParameters.get("ticketSet");
  let width = window.innerWidth;
  const [collectTicket, setCollectTicket] = useState([]);
  useEffect(() => {
    dispatch(tambolaGameHisTicketByGame({gameid}));
    dispatch(tambolaGameByID({gameid}))
    dispatch(getBoats())
  }, [dispatch, gameid, ticketSet]);
  const { tambolaGameByIdData, tambolaGameHisTicketByGameList } = useSelector((state) => state.commonReducer);
  const setSize = +ticketSet;
  let counter = 0;
  //select sets
  const handleClick = (target) => {
    target.classList.toggle('bg-warning');
    if (collectTicket.includes(target.dataset.setid)) {
      setCollectTicket(collectTicket.filter((item) => item !== target.dataset.setid));
    } else {
      setCollectTicket([...collectTicket, target.dataset.setid]);
    }
  };
  return (
    <>
    <Header />
    <Row>  
    <Col md={2} className="p-0"> 
    <SideBaar/>
    </Col> 
    <Col md={10}>
    <div className="mt-3">
      <Row>
        <h4 className="text-center">{tambolaGameByIdData?.name}</h4>
        <hr />
      </Row>
      <Row className='align-items-center'>
        <Col md={4}>
          <p className="mb-0">
            GameId:-{' '}
            <b>{`${tambolaGameByIdData?.idd} (${moment(tambolaGameByIdData?.start).format(
              'MMMM Do YY, h:mm:ss a',
            )})`}</b>
          </p>
        </Col> 
        <Col md={4} xs={6} sm={6}>
            <Button className="mx-1 w-100" variant="danger">Sold {tambolaGameByIdData?.totalBookedTicket}</Button>
        </Col>
        <Col md={4} xs={6} sm={6}>
            <Button className="mx-1 w-100" variant="success">Unsold {tambolaGameByIdData?.boxes - tambolaGameByIdData?.totalBookedTicket}</Button>
        </Col>
        <Col>
          <p className="mb-0">
            TicketPrice:- <b>{tambolaGameByIdData?.ticketPrice}</b>
          </p>
        </Col>
        <Col>
          <p className="mb-0">
            TicketSet:- <b>{tambolaGameByIdData?.ticketSet}</b>
          </p>
        </Col>
        <Col>
          <p className="mb-0">
            TotalBooked:- <b>{tambolaGameByIdData?.totalBookedTicket}</b>
          </p>
        </Col>
      </Row>
      <hr />
       <Container>
       <Row className={width > 500 ? "mb-3" : 'mb-2'}>
          {!!tambolaGameHisTicketByGameList &&
            tambolaGameHisTicketByGameList?.map((data, index) => {
              return (
                (((localStorage.getItem("userRole") !== "6") && localStorage.getItem("appSlug")?.length > 0) ? (data?.ticketid % setSize === 0) : data?.ticketid) && (
                  <Col md={1} sm={2} xs={2} className={`mb-2`} key={index}>
                    <Button
                      disabled={data?.booked ? true : false}
                      variant={
                        (data?.booked && `danger`) || (data?.booked === false && `success`)
                          ? (data?.booked && `danger`) || (data?.booked === false && `success`)
                          : `dark`
                      }
                      onClick={(e) => handleClick(e.target)}
                      data-setid={counter + 1}
                      className={`w-100 p-0`}
                    >
                      {++counter}
                    </Button>
                  </Col>
                )
              );
            })}
        </Row>
       </Container>
      {collectTicket.length > 0 && (
        <TambolaBookTicket bookticktgameId={gameid} ticketsNum={collectTicket} collectTicket={collectTicket} />
      )}
    </div>
    </Col>
    </Row>
    </>
  );
};

export default TambolaTicketBookingBoard;
