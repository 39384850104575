import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { tambolaFutureGames } from '../../../reducers/commonReducer';

const schema = yup.object().shape({
  name: yup.string().required("Game Name is Required."),
  autoMatic:yup.string().required("Select Game Type."),
  ticketSet:yup.string().required("TicketSet is Required."),
  price: yup.number("Must be number")
  .required("Price is required.")
  .positive()
  .integer()
  .min(5, "Minimum is 5 Rs"),
  gameHistoryId: yup.string().when('autoMatic', {
    is: "false",
    then: yup.string().required("Please Select Game."),
  }),
  box: yup.string().when('autoMatic', {
    is: "true",
    then: yup.string().required("Box is Required."),
  }),
  unsoldwinner: yup.string().when('autoMatic', {
    is: "true",
    then: yup.string().required("Unsoldwinner is Required."),
  }),
  start: yup.string().when('autoMatic', {
    is: "true",
    then: yup.string().required("Start Time is Required."),
  }),
});

function ActionTambolaGameSubadmin(props) {
  const [gameRewards, setGameRewards] = useState([]);
  const [gameType, setGameType] = useState();
  const [gameName, setGameName] = useState();
  const [liveJackPot, setliveJackPot] = useState();
  const [gameHisId, setGameHisId] = useState();
  const [gameticketSet, setGameticketSet] = useState();
  const [unsoldwinner, setunsoldwinner] = useState();
  const [box, setBox] = useState();
  const [start, setStart] = useState();
  const [price, setPrice] = useState();
  const [ticketSet, setTicketSet] = useState();
  const dispatch = useDispatch();
  const {
    api,
    title,
    jackpotPrice,
    rewards,
    id,
    btnFloat
  } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
  };
  const { getTambolaRewardList, addTambolaBySubAdminWaiting, upcommingGameList } = useSelector((state) => state.commonReducer);
  let rewdObject = {};
  if(!!rewards ) {
    rewards.map((data) => {
      return rewdObject[data?._id?.id] = data?.prize;  
    });
  }
  return (
    <>
        <Button variant="success" className={`m-1 py-1 px-2 ${btnFloat}`} onClick={handleShow}>
          {title} Game
        </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Game</Modal.Title>
        </Modal.Header>
        <Formik
            enableReinitialize={(gameType === "false") ? true : false}
            // enableReinitialize={true}
            validationSchema={schema}
            initialValues={{
            id,
            name : gameName,
            start:start,
            autoMatic:gameType,
            box:box,
            unsoldwinner:unsoldwinner,
            gameHistoryId:gameHisId,
            price,
            jackpotPrice:liveJackPot,
            ticketSet : (localStorage.getItem("appSlug")?.length > 0) ? (ticketSet ? ticketSet : gameticketSet) : 1,
            rewards: ((gameType === "false"))
            ?
            !!gameRewards && gameRewards.map((data) => {
              if (((localStorage.getItem("appSlug")?.length > 0) && 
                  (data?._id?.name === "Full Sheet" || data?._id?.name === "Half Sheet")) ||
                  ((localStorage.getItem("website")?.length > 0) && 
                  (data?._id?.name === "Set Corner"))) {
                return {
                  _id: data?._id?.id,
                  prize: 0,
                  name: data?._id?.name,
                }; 
              }
              return {
                _id: data?._id?.id,
                prize: data?.prize,
                name: data?._id?.name,
              };
            })
            : 
            !!getTambolaRewardList && getTambolaRewardList.map((data) => {
              if (((localStorage.getItem("appSlug")?.length > 0) && 
                  (data?.name === "Full Sheet" || data?.name === "Half Sheet")) || 
                  ((localStorage.getItem("website")?.length > 0) && 
                  (data?.name === "Set Corner"))) {
                return {
                  _id: data?.id,
                  prize: 0,
                  name: data?.name,
                }; 
              }
              return {
                _id: data?.id,
                prize: data?.prize,
                name: data?.name,
              };
            })
          }} 
          onSubmit={async (values, { resetForm }) => {
            // if (!values?.rewards[7].prize) {
            //   alert('Full house is required');
            //   return false;
            // }
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(tambolaFutureGames());
            handleClose();
          }}
        >
          {({ handleSubmit, handleChange,setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="px-3 pb-3">
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">GameName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Game Name"
                      name="name"
                      id={"name"}
                      value={values.name}
                      onChange={async(e) => {
                        await setFieldValue('name', e.target.value)
                        setGameName(e.target.value)
                      }}
                      isInvalid={!!errors.name}
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-1">Game Type</Form.Label>
                    <Form.Control
                      as="select"
                      id="gametype"
                      value={values.autoMatic}
                      onChange={async(e) => {
                        await setFieldValue('autoMatic', e.target.value)
                        setGameType(e.target.value);
                        if(e.target.value === "true"){
                          setGameHisId("")
                          setGameRewards("")
                          setliveJackPot("")
                          setGameticketSet("")
                        }
                      }}
                      name="autoMatic"
                      className="form-control"
                      isInvalid={!!errors.autoMatic}
                    >
                      <option value={''}>Select Type</option>
                      <option value={"false"}>Live Game</option>
                      <option value={"true"}>Automatic Game</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.autoMatic}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {values?.autoMatic === "true" &&
                <Row className="pb-2">
                <Col md={6}>
                <Form.Group className="pb-2">
                  <Form.Label className="mb-1">UnsoldWin</Form.Label>
                  <Form.Control
                    id="unsoldwinner"
                    as="select"
                    onChange={async (e) => {
                      await setFieldValue('unsoldwinner', values?.autoMatic === "true" ? e.target.value : '');
                      setunsoldwinner(values?.autoMatic === "true" ? e.target.value : '')
                    }}
                    name="unsoldwinner"
                    className="form-control"
                    value={values.unsoldwinner}
                    isInvalid={!!errors.unsoldwinner}
                  >
                    <option value={""}>Select UnsoldWin</option>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.unsoldwinner}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              </Row>
              }
              <Row className="pb-2">
                {
                 ((gameType === "false") && (upcommingGameList?.length > 0)) && <p className='mb-0 text-danger'>Please Select Any Game*</p>
                }
                {
                  (gameType === "false" && !!upcommingGameList) && upcommingGameList?.map((game, index) => {
                    return(
                        <Col md={3} key={index}>
                        <Form.Group className="pb-2">
                          <Button name="gameHistoryId" className='w-100 px-1' variant={game?.id === values.gameHistoryId ? 'success' : 'info'}
                            onClick={() => {
                            setGameHisId(game.id)
                            setGameRewards(game?.rewards)
                            setGameticketSet(game?.ticketSet)
                            setliveJackPot(game?.jackpotPrice)
                          }}>
                            <p className='mb-0'>{moment(game?.start).format("MM/DD/YYYY hh:mm a")}</p>
                            <p className='mb-0'>{game.name}</p>
                          </Button>
                           <Form.Control.Feedback type="invalid">{errors.gameHistoryId}</Form.Control.Feedback>
                        </Form.Group>
                        </Col>
                    )
                  }) 
                }
                {
                  ((gameType === "false") && (upcommingGameList?.length === 0)) && <h4 className='text-center text-warning'>Not Found Any Live Game</h4>
                }
               </Row>
               {(values.autoMatic === "true") && <Row className="pb-2">
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Box</Form.Label>
                    <Form.Control
                      type="number"
                      id="enterBox"
                      placeholder="Enter box"
                      name="box"
                      value={values.box}
                      onChange={async(e) => {
                        await setFieldValue('box', e.target.value)
                        setBox(e.target.value);
                      }}
                      isInvalid={!!errors.box}
                    />
                    <Form.Control.Feedback type="invalid">{errors.box}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Start Date</Form.Label>
                    <Form.Control
                      id="start"
                      type="datetime-local"
                      name="start"
                      value={values?.start ? moment(values.start).format('YYYY-MM-DDTHH:mm') : ''}
                      onChange={async(e) => {
                        await setFieldValue('start', e.target.value)
                        setStart(e.target.value);
                      }}
                      isInvalid={!!errors.start}
                    />
                    <Form.Control.Feedback type="invalid">{errors.start}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
               }
              <Row className="pb-2">
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Ticket Price</Form.Label>
                    <Form.Control
                      id="price"
                      type="number"
                      placeholder="Price"
                      name="price"
                      value={values.price}
                      onChange={async(e) => {
                        await setFieldValue('price', e.target.value)
                        setPrice(e.target.value);
                      }}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {
                 (localStorage.getItem("appSlug")?.length > 0) && <Col md={4}>
                 <Form.Group>
                   <Form.Label className="mb-1">Select Sets</Form.Label>
                   <Form.Control
                     as="select"
                     onChange={async (e) => {
                       await setFieldValue('ticketSet', +e.target.value);
                       setTicketSet(+e.target.value)
                     }}
                     id="ticketset"
                     name="ticketSet"
                     className="form-control"
                     value={values.ticketSet}
                     isInvalid={!!errors.ticketSet}
                     disabled={((localStorage.getItem("appSlug")?.length > 0) && gameType === "true") ? false : true}
                   >
                     <option value="">Select Set</option>
                     <option value="1">1</option>
                     <option value="2">2</option>
                     <option value="3">3</option>
                     <option value="6">6</option>
                   </Form.Control>
                   <Form.Control.Feedback type="invalid">{errors.ticketSet}</Form.Control.Feedback>
                 </Form.Group>
               </Col>
                }
                <Col md={4}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Jackpot Price</Form.Label>
                    <Form.Control
                      type="number"
                      id="Price"
                      placeholder="Price"
                      name="jackpotPrice"
                      value={values.jackpotPrice}
                      onChange={handleChange}
                      isInvalid={!!errors.jackpotPrice}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.jackpotPrice}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label className="mb-0">Rewards</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Label className="mb-0">Rewards Price</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <FieldArray name="rewards">
                    {() => (
                      <>
                        {(values.rewards) &&
                          values.rewards.map((data, index) => {
                            return (
                              <React.Fragment key={index}>
                                {
                                  !(data?.prize === 0) && <Row>
                                    <Col md={6} sm={12}>
                                      <Field
                                        className="p-2 mb-1 w-100"
                                        type="text"
                                        value={values.rewards[index]?.name}
                                        id={`rewards.${index}.name`}
                                        name={`rewards.${index}.name`}
                                        disabled
                                      />
                                    </Col>
                                    <Col md={6} sm={12}>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        id={`rewards.${index}.prize`}
                                        value={values?.rewards[index]?.prize}
                                        name={`rewards.${index}.prize`}
                                        isInvalid={!!errors.rewards}
                                      />
                                    </Col>
                              </Row>
                              }
                              </React.Fragment>
                            );
                          })}
                      </>
                    )}
                  </FieldArray>
                  <Form.Control.Feedback type="invalid">{errors.rewards}</Form.Control.Feedback>
                </Col>
              </Row>
              {/* <Row>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-0">Video ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Videoid"
                      name="videoid"
                      value={values.videoid}
                      onChange={handleChange}
                      isInvalid={!!errors.videoid}
                    />
                    <Form.Control.Feedback type="invalid">{errors.videoid}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label className="mb-1">UnsoldWin</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue('unsoldwinner', e.target.value);
                      }}
                      name="unsoldwinner"
                      className="form-control"
                      value={values.unsoldwinner}
                      isInvalid={!!errors.unsoldwinner}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row> */}
              <Modal.Footer>
                <div id='tmpRemoveSubmitbutton'>
                  <Button type="submit" variant='success' className="mt-1" disabled={addTambolaBySubAdminWaiting === 0 ? true : false}>
                    Submit
                  </Button>
                </div>
                <div className="loading-area" id="loading-icon">
                  <div className="loader">
                      <div></div>
                  </div>
                </div> 
                <Button variant="danger" onClick={handleClose} className="mt-1">
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ActionTambolaGameSubadmin;
